<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="overview-container">
        <div class="title">
            客服画像概览
            <el-date-picker v-model="satrtEndDate" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="() => { changeDate() }"></el-date-picker>
        </div>
        <div class="grey-line"></div>
        <div class="overview-main">
            <div class="main-item">
                <div class="main-title">客服分布</div>
                <div v-show="pieData && pieData.length"  class="main-item-bottom">
                    <div class="serPie-container">
                        <div id="serPie"></div>
                        <span class="total-btn">{{totalPie}}</span>
                    </div>
                    <div class="serNum-container">
                        <div v-for="(item, index) in pieData" :key="index" class="serNum-item">
                            <span class="serNum-name">{{item.name}}</span>
                            <div class="serNum-value" :style="`color: ${color[index%color.length]}`">{{item.num}}</div>
                        </div>
                    </div>
                </div>
                <img class="null-img" v-show="!(pieData && pieData.length)" src="../../assets/images/null_img.png">
            </div>
            <div class="main-item">
                <div class="main-title">咨询量排名</div>
                <div v-if="tableData && tableData.length" class="main-item-bottom" style="padding: 32px;box-sizing: border-box;align-items: flex-start;">
                    <el-table :data="tableData">
                        <el-table-column label="排名">
                            <template slot-scope="scope">
                                <div> <i :style="`color: ${scope.$index == 0 ? '#FFD700' : scope.$index == 1 ? '#C0C0C0' : scope.$index == 2 ? '#B87333' : '#fff'}`" class="iconfont icon-riLine-medal-line"></i> {{scope.$index == 9 ? '' : '0'}}{{scope.$index + 1}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="客服">
                            <template slot-scope="scope">
                                <div>{{scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="客服等级">
                            <template slot-scope="scope">
                                <div class="table-label" :style="`color: ${colorFun(scope.row.level)};border-color: ${colorFun(scope.row.level)}`">{{scope.row.level}}</div>
                                <!-- <div class="table-label" :style="`color: ${color[scope.$index%color.length]};border-color: ${color[scope.$index%color.length]}`">{{scope.row.level}}</div> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="咨询量">
                            <template slot-scope="scope">
                                <div>{{scope.row.num}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <img class="null-img" v-else src="../../assets/images/null_img2.png">
            </div>
        </div>
        <div class="grey-line"></div>
        <div class="serTitle">
            客服服务数据
            <div></div>
        </div>
        <div class="common-padding">
            <el-table class="common-table" border :data="serKPIList">
                <el-table-column prop="subaccountName" label="客服名称" fixed>
                    <template slot-scope="scope">
                        <span>{{scope.row.subaccountName || "--"}}</span>
                    </template>
                </el-table-column>
               
                <el-table-column label="首响均值" prop="firsrTime">
                    <template slot-scope="scope">
                        <span>{{scope.row.firsrTime || "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="平响均值" prop="avgTime">
                    <template slot-scope="scope">
                        <span>{{scope.row.avgTime || "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="问答比" prop="qaRate">
                    <template slot-scope="scope">
                        <span>{{ scope.row.qaRate ? (scope.row.qaRate * 100).toFixed(2) == "NaN" ? "--" : (scope.row.qaRate * 100).toFixed(2) + "%" : "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="邀评率" prop="inviteComments">
                    <template slot-scope="scope">
                        <span>{{ scope.row.inviteComments ? (scope.row.inviteComments * 100).toFixed(2) == "NaN" ? "--" : (scope.row.inviteComments * 100).toFixed(2) + "%" : "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="服务满意度" prop="satisfaction">
                    <template slot-scope="scope">
                        <span>{{ scope.row.satisfaction || "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="工单错误率" prop="orderErrorRate" >
                    <template slot-scope="scope">
                        <span>{{ scope.row.orderErrorRate ? (scope.row.orderErrorRate * 100).toFixed(2) == "NaN" ? "--" : (scope.row.orderErrorRate * 100).toFixed(2) + "%" : "--"}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="质检错误率" prop="qualityErrorRate">
                    <template slot-scope="scope">
                        <span>{{ scope.row.qualityErrorRate ? (scope.row.qualityErrorRate * 100).toFixed(2) == "NaN" ? "--" : (scope.row.qualityErrorRate * 100).toFixed(2) + "%" : "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="服务高压线" prop="serviceHighVoltage">
                    <template slot-scope="scope">
                        <span>{{ scope.row.serviceHighVoltage || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="遗漏客诉" prop="missFeedback">
                    <template slot-scope="scope">
                        <span>{{ scope.row.missFeedback || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="散客满意度" prop="satisfaction">
                    <template slot-scope="scope">
                        <span>{{ scope.row.satisfaction || "--"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.page"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件
import { serPieData, serviceTop, serviceKPIList } from "../../service/portrait.js"
import { Config, DateTransform } from "../../utils/index.js"
import { mapState } from "vuex";
export default {
    data () {
        return {
            satrtEndDate: [new Date(), new Date()], // 时间筛选
            loading: false,
            color: [
                    "#E83636",
                    "#FF974C",
                    "#FFDC4C",
                    "#A8DFFA",
                    "#5B6E96",
                    "#62D9AD",
                    "#5AAEF3",
                    "#6D9AE7",
                    "#4A6FE2",
                    "#6D61E4"
                ],
            divWidth: "",
            totalPie: '0',
            pieData: [],
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
            tableData: [],
            serKPIList: [], // 客服服务数据
            params: {
                page: 1,
                pageSize: 10
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100],
            }
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    mounted () {
        this.getComId()
    },
    methods: {
        colorFun (text) {
            let pieData = this.pieData
            let color = [...this.color]
            let indexColor = -1
            if (pieData && pieData.length) {
                pieData.map((item, index) => {
                    if (text == item.name) {
                        indexColor = index
                    }
                })
            }
            return indexColor == -1 ? '#ccc' : color[indexColor%color.length]
        },
        getComId() { // 获取comID&&请求列表
            setTimeout(() => {
                this.companyId = this.comId
                this.loading = true
                this.serPieData().then(() => {
                    this.serviceTop().then(() => {
                        this.serviceKPIList().then(() => {
                            this.loading = false
                        })
                    })
                })
            },400)
        },
        async serviceKPIList() {
            let params = {...this.params}
            params.companyId = this.companyId
            params.startDate = DateTransform(this.satrtEndDate[0])
            params.endDate = DateTransform(this.satrtEndDate[1])
            this.loading = true
            let { data } = await serviceKPIList(params)
            this.loading = false
            this.serKPIList = data.list
            this.pagination.total = data.total
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e
            } else {
                this.params.page = e
            }
            this.serviceKPIList()
        },
        changeDate() { // 更改时间
            this.serviceTop()
            this.serviceKPIList()
        },
        async serviceTop() { // 客服排行榜
            let { data } = await serviceTop({ companyId: this.companyId, type: 'DESC', startDate: DateTransform(this.satrtEndDate[0]), endDate: DateTransform(this.satrtEndDate[1]) })
            this.tableData = data
        },
        async serPieData() {
            let { data } = await serPieData({ companyId: this.companyId, type: 0 })
            if (data && data.list && data.list.length) {
                data.list.map((item) => {
                    item.value = item.num
                    item.name = item.serviceLevel
                })
            } else {
                data = []
            }
            this.totalPie = data.total || 0
            this.pieData = data.list || []
            this.drawPie(data.list || []) 
        },
        drawPie(pieData) { // 圆环图
            let myChart = this.$echarts.init(document.getElementById('serPie'))
            myChart.setOption({
                title: {
                    text: "\t", //主标题文本
                    subtext: '客服总数', //副标题文本
                    left: "center",
                    top: "38%",
                    textStyle: {
                        fontSize: 34,
                        color: "#333",
                        align: "center"
                    },
                    subtextStyle: {
                        fontSize: 14,
                        color: "#333"
                    }
                },
                tooltip: {
                    trigger: "item"
                },
                series: [
                    {
                        hoverAnimation: false, // 取消掉环形图鼠标移上去时自动放大
                        type: "pie",
                        radius: ["75%", "100%"], //第一个值控制空心圆大小 第二个值控制外圆大小
                        avoidLabelOverlap: false,
                        label: {
                        show: false,
                        position: "center"
                        },
                        labelLine: {
                        show: false
                        },
                        data: pieData
                    }
                ],
                color: this.color,
                animation: true,
                animationThreshold: 1500
            });
        },
        sizeChange () { // 窗口改变
            var mainChart = document.getElementById('serPie')
            let charts = this.$echarts.init(mainChart); // 图标ID初始化--使用它的resize()
            let erd = elementResizeDetectorMaker({ // 初始化element-resize-detector组件
                strategy: "scroll", // <- 推荐监听滚动，提升性能
                callOnAdd: true // 添加侦听器时是否应调用,默认true
            })
            erd.listenTo(mainChart, function() { //监听id的元素 大小变化 参数element
                charts.resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
            });
        },
        // reSize() {
        //     let myChart = this.$echarts.init(document.getElementById('serPie'));
        //     window.onresize = function() {
        //         //重置容器高宽
        //         myChart.style.width = this.divWidth + "px"
        //         myChart.style.height = this.divWidth + "px"
        //         myChart.resize();
        //     };
        // },
        // getWidth() {
        //     this.divWidth = document.getElementById(this.pieName).clientWidth;
        // }
    }
}
</script>
<style lang="less" scoped>
.overview-container {
    text-align: left;
    .null-img {
        display: block;
        width: 320px;
        margin: 40px auto 40px;
    }
    .serTitle {
        height: 88px;
        line-height: 88px;
        text-indent: 16px;
        font-size: 24px;
        color: #333;
        position: relative;
        &::after {
            display: block;
            content: ' ';
            width: 120%;
            position: absolute;
            bottom: 0;
            left: -10%;
            height: 1px;
            background: #eee;
        }
    }
    .table-label {
        display: inline-block;
        height: 24px;
        height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border-radius: 12px;
        border: 1px solid #eee;
    }
    /deep/.el-table th {
        background: #eee;
    }
    .main-item-bottom {
        display: flex;
        // height: 544px;
        align-items: center;
        justify-content: space-evenly;
        min-height: 520px;
    }
    .serNum-container {
        background: rgba(24, 144, 255, 0.12);
        border-radius: 6px;
        box-sizing: border-box;
        padding: 22px;
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
        min-height: 360px;
        margin: 32px 0;
        .serNum-item {
            width: 100px;
            .serNum-name {
                color: #333;
                font-size: 14px;
            }
            .serNum-value {
                font-size: 28px;
                text-decoration: underline;
                margin: 6px 0 16px;
            }
        }
    }
    .serPie-container, .serNum-container {
        width: 360px;
        height: 360px;
        position: relative;
        .total-btn {
            position: absolute;
            top: 140px;
            left: 80px;
            right: 80px;
            height: 32px;
            text-align: center;
            margin: auto;
            font-size: 24px;
            text-decoration: underline;
        }
        #serPie {
            width: 360px;
            height: 360px;
        }
    }
    
    .overview-main {
        display: flex;
        .main-item {
            width: 50%;
            .main-title {
                text-indent: 32px;
                height: 80px;
                line-height: 80px;
                font-size: 24px;
                position: relative;
                // border-bottom: 1px solid #eee;
                color: #333;
                 &::after {
                    display: block;
                    content: ' ';
                    width: 120%;
                    position: absolute;
                    bottom: 0;
                    left: -10%;
                    height: 1px;
                    background: #eee;
                }
            }
            &:nth-of-type(1) {
                box-sizing: border-box;
                border-right: 16px solid #eee;
            }
        }
    }
    .title {
        box-sizing: border-box;
        padding: 12px 32px 28px;
        height: 68px;
        color: #333;
        font-size: 28px;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
</style>